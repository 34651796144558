import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {Message} from '../../../../models/message';
import {MessageRead} from '../../../../models/message-read';
import {MessageAck} from '../../../../models/message-acknowledgement';
import {MessageStar} from '../../../../models/message-star';

import {SocketService} from '../../socket/socket.service';
import {LocalStorageManagerService} from '../../../../utilities/local-storage/local-storage-manager.service';
import {AccountManagerService} from '../../account/account-manager.service';

import {ChatMessageService} from './chat-message/chat-message.service';
import {NewsMessageService} from './news-message/news-message.service';

import {LoggerService} from '../../../../utilities/logger/logger.service';


import * as _ from 'lodash';
import {UserContactService} from '../user-contact/user-contact.service';
import {MessageDelivery} from '../../../../models/message-delivery';
import {MessageEmoji} from '../../../../models/message-emoji';
import {ChatService} from '../chat/chat.service';
import {TimestampService} from '../../../../utilities/timestamp/timestamp.service';
import {InfoMessageService} from './info-message/info-message.service';
import {TnNotificationService} from '../../../../utilities/tn-notification/tn-notification.service';
import {TranslateManagerService} from '../../../../utilities/translate/translate-manager.service';
import {WindowFocusService} from '../../../../utilities/window-focus/window-focus.service';
import {UtilitiesService} from '../../../../utilities/service/utilities.service';
import {AMQPRoutingKey} from '../../../../constants/amqp-routing-key.constant';
import {MessageTypeConstant} from '../../../../constants/message-type.constant';
import {TeamNoteLocalStorageKeyConstants} from '../../../../constants/local-storage-key.constant';
import {MessageDelete} from '../../../../models/message-delete';
import {AttachmentTypeConstant} from '../../../../constants/attachment-type.constant';
import {FileManagerService} from '../../../../utilities/file-manager/file-manager.service';
import {StarredMessagesService} from '../../../../webclient/starred-messages/starred-messages.service';
import {EmojiService} from '../../../../utilities/emoji/emoji.service';
import {CronjobTrackerService} from '../../../../utilities/cronjob-tracker/cronjob-tracker.service';
import {CriticalMessage} from '../../../../utilities/cronjob-tracker/models/cronjob';

interface Messages {
  [messageId: string]: Message
}

@Injectable()
export class MessagesService {
  messages: Messages = {};

  unreadMessages: Messages = {};
  unreadNews: Messages = {};

  // for tracking current opened modal
  activeMessageId: string = '';
  activeMessage$: BehaviorSubject<Message> = new BehaviorSubject<Message>(null);

  public _messageQueueName;

  private _pendingDeliveryMessageId: string[] = [];
  private _pendingDeliveryChatId: string[] = [];

  constructor(
    private _socketService: SocketService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _loggerService: LoggerService,
    private _accountManagerService: AccountManagerService,
    private _chatMessageService: ChatMessageService,
    private _newsMessageService: NewsMessageService,
    private _userContactService: UserContactService,
    private _chatService: ChatService,
    private _timestampService: TimestampService,
    private _infoMessageService: InfoMessageService,
    private _tnNotificationService: TnNotificationService,
    private _translateManagerService: TranslateManagerService,
    private _windowFocusService: WindowFocusService,
    private _utilitiesService: UtilitiesService,
    private _fileManagerService: FileManagerService,
    private _emojiService: EmojiService,
    private injector: Injector,
  ) { }

  /**
   * Init all messages objects
   *
   * @memberof MessagesService
   */
  initMessages(): void {
    this._loggerService.debug('Reset messages');
    this.messages = {};
    this._chatMessageService.initChatMessages();
    this._newsMessageService.initNewsMessages();
    this._infoMessageService.initInfoMessages();
  }

  /**
   * Handle all messages received from socket
   * 1. Handle messages according to messate.type
   * 2. Send Message Delivery after parsing all received messages
   * 3. If it is response from load_recent_message, calculate initial unread counts
   * 4. Try to update active chat room messages
   * 5. Update all messages subjects (chatMessages$, newsMessages$, activeMessage$ (for news modal), unreadMessage$, unreadChatMessages$)
   *
   * @param {any} incomingMessage
   * @memberof MessagesService
   */
  receiveMessages(incomingMessage, chatId?: string): void {
    let correlationId = incomingMessage.headers['correlation-id'];
    let isRecentMessage = correlationId == AMQPRoutingKey.CORRELATION_ID.LOAD_RECENT_MESSAGE;
    let isLoadHistory = correlationId ? correlationId.indexOf(AMQPRoutingKey.CORRELATION_ID.LOAD_HISTORY) != -1 : false;

    let messages: Message[] = JSON.parse(incomingMessage.body);

    let isNewsRelated: boolean = false;

    let allChatIds = [];
    _.each(messages, (m) => {
      switch (_.toInteger(m.type)) {
        case MessageTypeConstant.RESPONSE:
        case MessageTypeConstant.ACTION:
          break;
        case MessageTypeConstant.TEXT:
        case MessageTypeConstant.ATTACHMENT:
        case MessageTypeConstant.LOCATION:
        case MessageTypeConstant.STICKER:
        case MessageTypeConstant.JOIN_CHAT:
        case MessageTypeConstant.LEAVE_CHAT:
        case MessageTypeConstant.CHANGE_CHAT_ADMIN:
          allChatIds = _.union(allChatIds, m.chat_ids);
          this.updateMessage(m, isLoadHistory, isRecentMessage, true);
          this._chatMessageService.updateChatMessage(m);
          break;
        case MessageTypeConstant.NEWS:
        case MessageTypeConstant.VOTE:
          isNewsRelated = true;
          this.updateMessage(m, isLoadHistory, isRecentMessage, false);
          this._newsMessageService.updateNewsMessage(m);
          break;
        case MessageTypeConstant.MESSAGE_UPDATE:
          this._infoMessageService.insertMessageUpdateStatus(m);
          const chatIds = chatId ? [chatId] : [this._chatService.activeChat.chat_id]
          allChatIds = _.union(allChatIds, chatIds);
          this._chatMessageService.checkUpdatedChatMessage(m, chatIds);
          break;  
        case MessageTypeConstant.EMOJI:
        case MessageTypeConstant.UNEMOJI:
          this._infoMessageService.insertMessageEmojiStatus(m);
          let targetEmojiChatIds = this.updateMessageEmojiStatus(m);
          allChatIds = _.union(allChatIds, targetEmojiChatIds);
          break;
        case MessageTypeConstant.READ:
        case MessageTypeConstant.BULK_READ:
          this._infoMessageService.insertMessageReadStatus(m);
          let targetChatIds = this.updateMessageReadStatus(m);
          allChatIds = _.union(allChatIds, targetChatIds);
          break;
        case MessageTypeConstant.MESSAGE_DELIVERY:
          this._infoMessageService.insertMessageDeliveryStatus(m);
          let tmpChatIds = this.updateMessageDeliveryStatus(m);
          allChatIds = _.union(allChatIds, tmpChatIds);
          break;
        case MessageTypeConstant.MESSAGE_ACKNOWLEDGEMENT:
          this._infoMessageService.insertMessageAckStatus(m);
          let targetAckChatIds = this.updateMessageAckStatus(m);
          allChatIds = _.union(allChatIds, targetAckChatIds);
          break;
        case MessageTypeConstant.MESSAGE_STAR:
        case MessageTypeConstant.MESSAGE_UNSTAR:
          // console.log('MESSAGE_STAR message', m)
          this._infoMessageService.insertMessageStarStatus(m);
          let targetStarOrUnstarChatIds = this.updateMessageStarStatus(m);
          allChatIds = _.union(allChatIds, targetStarOrUnstarChatIds);
          break;
        case MessageTypeConstant.MESSAGE_DELETE:
          allChatIds = _.union(allChatIds, m.chat_ids);
          this.handleDeleteMessage(m);
          break;
      }
    });

    if (this._pendingDeliveryMessageId.length > 0) {
      this.sendMessageDelivery(_.find(this._pendingDeliveryChatId, (cid) => {
        return cid.indexOf('.category.') == -1;
      }), this._pendingDeliveryMessageId);
      this._pendingDeliveryChatId = [];
      this._pendingDeliveryMessageId = [];
    }

    if (isRecentMessage) {
      //calculate initial unread count
      this.calculateUnreadCount();
    } else {
      if (isNewsRelated) {
        this._newsMessageService.updateNewsSubject();
      }
    }

    this.updateActiveMessageSubject();
    this._chatMessageService.updateChatMessageSubject();

    if (!isLoadHistory) {
      // try to update active chatroom message subject if not load_history
      this._chatMessageService.tryUpdateActiveChatRoomMessageSubject(allChatIds);
    }

    // update unread counts
    this._chatMessageService.updateUnreadMessageSubject();
    this._newsMessageService.updateUnreadNewsSubject();

  }

  /**
   * Update Chat Messages / News Messages
   *
   * 1. If it is a new message, add to pendingDeliveryMessageId and pendingDeliveryChatId
   * 1.1. Handle New Chat Message & New News separately
   * 2. If message is expired, delete from unread arrays
   * 3. Store received message
   *
   * @param {Message} m
   * @param {boolean} isLoadHistory
   * @param {boolean} isRecentMessage
   * @param {boolean} isChatMessage
   * @memberof MessagesService
   */
  updateMessage(m: Message, isLoadHistory: boolean, isRecentMessage: boolean, isChatMessage: boolean) {
    let existingMessage = this.getMessageByMessageId(m.message_id);
    if (!existingMessage) {
      // This is a new message
      this._pendingDeliveryMessageId.push(m.message_id);
      this._pendingDeliveryChatId = _.union(this._pendingDeliveryChatId, m.chat_ids ? m.chat_ids : []);

      if (isChatMessage) {
        this.handleNewChatMessage(m, isRecentMessage);
      } else {
        this.handleNewNews(m);
      }
    }

    // If message is expired, delete from unread arrays
    if (this._utilitiesService.checkIfMessageExpired(m.expiry_time)) {
      this._chatMessageService.removeUnreadMessageByMessageId(m.message_id);
      this._newsMessageService.removeUnreadNewsByMessageId(m.message_id);
    }

    // store received message
    this.insertOrUpdateMessage(m);
  }

  /**
   * Handle New Chat Message
   * Check if message is new by comparing last disconnect time / login time
   * Show browser notification if it is new message
   *
   * @param {Message} m
   * @param {boolean} isRecentMessage
   * @returns
   * @memberof MessagesService
   */
  handleNewChatMessage(m: Message, isRecentMessage: boolean) {
    if (!m.chat_ids.length) {
      return;
    }
    // if newly received msg is expired already, return;
    if (this._utilitiesService.checkIfMessageExpired(m.expiry_time)) {
      return;
    }

    // only handle real message
    let targetMessageTypes = [
      MessageTypeConstant.TEXT,
      MessageTypeConstant.ATTACHMENT,
      MessageTypeConstant.LOCATION,
      MessageTypeConstant.STICKER
    ];
    if (_.indexOf(targetMessageTypes, _.toInteger(m.type)) === -1) {
      return;
    }

    // find chat object, if target chat is not a real chat room, return;
    let chat = this._chatService.getChatRoomByChatId(m.chat_ids[0]);
    if (!chat) {
      let newsChat = this._chatService.getNewsChannelOrCategoryByChatId(m.chat_ids[0]);
      if (!newsChat) {
        this._loggerService.warn('CANNOT FIND CHAT : ' + m.chat_ids[0]);
      }
      // if message is for news channel (it is a news comment, do not handle notification for now TODO: ??)
      return;
    }

    // if not recent message, set target chat to be visible again
    if (!isRecentMessage) {
      this._chatService.updateChatIsVisibleState(chat.chat_id, true);

      if (!this._chatService.activeChat) {
        // unarchive chat when the chat is inactive status
        this._chatService.updateChatIsArchivedState(chat.chat_id, false)
      }
    }

    //if there is disconnect time, use it in stead of login time
    //when reconnect, login time will become the NEW connect time, need the previous disconnect time to compare add unread or not
    //if no dc time = fresh login, use normal login time
    let disconnectTime = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DISCONNECT_TIME);
    let targetCompareTime = disconnectTime ? disconnectTime : this._socketService._loginTime;

    let isSentByMe = m.sent_by === this._accountManagerService.userId;

    if (
      chat &&
      this._timestampService.checkIfTimeCorrectOrder(targetCompareTime, m.timestamp) &&
      !isSentByMe &&
      !this._chatMessageService.getUnreadMessageByMessageId(m.message_id)
    ) {
      this._chatMessageService.insertUnreadMessage(m);

      // check if chat is muted
      let isChatMuted = chat.isMuted;
      // check if window is active
      let isWindowActive = this._windowFocusService.isWindowFocued;
      // check if target chat is active
      let isCurrentChatActive = this._chatService.activeChat ? (this._chatService.activeChat.chat_id == chat.chat_id) : false;
      
      const parsedBody = JSON.parse(m.body);
      if (parsedBody.acknowledgement) {
        if (parsedBody.acknowledgement.is_critical === 1) {
          const _cronjobTrackerService = this.injector.get<CronjobTrackerService>(CronjobTrackerService); // solve the circular dependency
          _cronjobTrackerService.addCriticalMessageToNotification(
            m.message_id, 
            new CriticalMessage(
              chat.displayName, 
              this._timestampService.getNowSecondString(),
              this._timestampService.getNowDisplayDateTime(),
              'GENERAL.NEW_CRITICAL_MESSAGE_NOTIFICATION'
            )
          )
        }
      }
      
      if (
        (!isCurrentChatActive || !isWindowActive) &&
        !isSentByMe &&
        !isRecentMessage &&
        !isChatMuted
      ) {
        // let sender = this._userContactService.getUserContactByUserId(m.sent_by).name;
        // let group;
        // if (chat.isGroup) {
        //   group = chat.name;
        // }
        // let msg;

        let chatTarget = chat.displayName;
        let chatAvatar = chat.chatTarget ? chat.chatTarget.pic : chat.pic;
        // console.log('chat', chat);
        // console.log('chatAvatar', chatAvatar);

        let notiMsg;

        let notificationStr = 'GENERAL.NEW_MESSAGE_NOTIFICATION';

        // const parsedBody = JSON.parse(m.body);
        if (parsedBody.acknowledgement) {
          if (parsedBody.acknowledgement.is_critical === 1) {
            notificationStr = 'GENERAL.NEW_CRITICAL_MESSAGE_NOTIFICATION';
          }
        }

        // TODO: intergrate with notification setting (show content, sender or not)
        this._translateManagerService.getTranslationByKey(
          notificationStr,
          (s) => {
            notiMsg = s;
          },
          {CHAT: chatTarget}
        );
        this._tnNotificationService.showBrowserNotification(notiMsg, () => {
          if (window['tnElectronApi']) {
            console.warn('found tnElectronApi');
            window['tnElectronApi'].focus();
          } else {
            window.focus();
          }
          this._chatService.updateActiveChatSubject(chat);
          this._chatService.goToChatPage();
        }, chatAvatar, chat.isGroup);
      }

    }

  }

  /**
   * Similar to handleNewChatMessage
   * Compare disconnect time / login time to show browser notification if news is new
   *
   * @param {Message} m
   * @returns
   * @memberof MessagesService
   */
  handleNewNews(m: Message) {
    // if newly received News is expired already, return;
    if (this._utilitiesService.checkIfMessageExpired(m.expiry_time)) {
      return;
    }

    //if there is disconnect time, use it in stead of login time
    //when reconnect, login time will become the NEW connect time, need the previous disconnect time to compare add unread or not
    //if no dc time = fresh login, use normal login time
    let disconnectTime = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DISCONNECT_TIME);
    let targetCompareTime = disconnectTime ? disconnectTime : this._socketService._loginTime;

    if (
      this._timestampService.checkIfTimeCorrectOrder(targetCompareTime, m.timestamp) &&
      !this._newsMessageService.getUnreadNewsByMessageId(m.message_id)
    ) {
      this._newsMessageService.insertUnreadNews(m);
      let content = JSON.parse(m.body).content;
      this._tnNotificationService.showBrowserNotification(content, () => {
        window.focus();
        this._newsMessageService.goToNewsPage();
      });
    }

  }

  /**
   * Get all chat messages and calculate unread message count
   *
   * @memberof MessagesService
   */
  calculateUnreadCount() {
    let unreadIds = [];
    // only handle real message
    let targetMessageTypes = [
      MessageTypeConstant.TEXT,
      MessageTypeConstant.ATTACHMENT,
      MessageTypeConstant.LOCATION,
      MessageTypeConstant.STICKER
    ];
    _.each(this._chatMessageService.chatMessages, (messagesOfChat, chatId) => {
      // only handle message in real chat room
      let chat = this._chatService.getChatRoomByChatId(chatId);
      if (!chat) {
        return;
      }
      _.each(messagesOfChat, (m) => {
        if (_.indexOf(targetMessageTypes, _.toInteger(m.type)) === -1) {
          return;
        }
        if (m.sent_by !== this._accountManagerService.userId) {
          if (!this._infoMessageService.checkIfMessageIsReadByMe(m.message_id)) {
            unreadIds.push(m.message_id);
            this._chatService.updateChatIsVisibleState(chat.chat_id, true);
            this._chatMessageService.insertUnreadMessage(m);
          }
        }
      });
    });
    // console.log('unreadIds', unreadIds);
  }

  /**
   * Add current user related message read to target messages
   *
   * Only handle message read if
   * 1. message read is sent by current user
   * or
   * 2. target emssage is sent by current user
   * (This logic is only valid in web-client as we will use HTTP API to get message's info anyway, no need to handle extra messages)
   *
   * @param {MessageRead} mr
   * @returns {string[]}
   * @memberof MessagesService
   */
  updateMessageReadStatus(mr: MessageRead): string[] {
    let chatIds = [];
    let messageIds = mr.body.split(' ');
    _.each(messageIds, (id) => {
      let m = this.getMessageByMessageId(id);
      if (m) {
        if (m.sent_by == this._accountManagerService.userId || mr.sent_by == this._accountManagerService.userId) {
          chatIds = _.union(chatIds, m.chat_ids);
          if (!m.read) {
            m.read = [];
          }
          let r = {
            body: id,
            sent_by: mr.sent_by,
            timestamp: mr.timestamp,
            type: mr.type
          };
          if (!_.find(m.read, {sent_by: r.sent_by})) {
            m.read.push(r);
          }
          if (mr.sent_by === this._accountManagerService.userId) {
            // delete from unread arrays
            this._chatMessageService.removeUnreadMessageByMessageId(id);
            this._newsMessageService.removeUnreadNewsByMessageId(id);
          }
        }
      } else {
        this._loggerService.warn('CANNOT FIND MESSAGE: ' + id);
      }
    });
    return chatIds;
  }

  updateMessageAckStatus(ma: Message): string[] {
    let chatIds = [];
    let messageIds = ma.body.split(' ');
    _.each(messageIds, (id) => {
      chatIds = _.union(chatIds, ma.chat_ids);
    });
    return chatIds;
  }

  /**
   * Update message delivery status (currently not using)
   * (we will use HTTP API to get message's info anyway, no need to handle extra messages)
   *
   * @param {MessageDelivery} md
   * @memberof MessagesService
   */
  updateMessageDeliveryStatus(md: MessageDelivery) {
    let chatIds = [];
    let messageIds = md.body.split(' ');
    _.each(messageIds, (id) => {
      let m = this.getMessageByMessageId(id);
      if (m) {
        // if (m.sent_by == this._accountManagerService.userId || md.sent_by == this._accountManagerService.userId) {
        if (m.sent_by == this._accountManagerService.userId) {
          chatIds = _.union(chatIds, m.chat_ids);

          if (!m.delivery) {
            m.delivery = [];
          }
          let d = {
            body: id,
            sent_by: md.sent_by,
            timestamp: md.timestamp,
            type: md.type
          };
          if (!_.find(m.delivery, {sent_by: d.sent_by})) {
            m.delivery.push(d);
          }
        }
      } else {
        this._loggerService.warn('CANNOT FIND MESSAGE: ' + id);
      }
    });

    return chatIds
  }

  /**
   * Similar logic to Message Read
   * Add current user related message star to target messages
   *
   * Only handle message read if message star is sent by current user
   * 
   * @param {MessageStar} mr
   * @returns {string[]}
   * @memberof MessagesService
   */
  updateMessageStarStatus(ms: Message): string[] {
    let chatIds = [];
    let messageIds = ms.body.split(' ');
    _.each(messageIds, (id) => {
      if (ms.sent_by == this._accountManagerService.userId) {
        chatIds = _.union(chatIds, ms.chat_ids);
      }
    });
    return chatIds;
  }

  updateMessageEmojiStatus(me: Message) {
    let chatIds = [];
    let { content, message_id } = JSON.parse(me.body)

    let messageIds = message_id.split(' ');

    _.each(messageIds, (id) => {
      chatIds = _.union(chatIds, me.chat_ids);
    });
    return chatIds
  }

  /**
   * Update received message by message_id
   *
   * @param {Message} m
   * @memberof MessagesService
   */
  insertOrUpdateMessage(m: Message) {
    this.messages[m.message_id] = _.assign(this.messages[m.message_id], m);
  }

  handleDeleteMessage(m: MessageDelete): void {
    let deletedMessageIds = m.body.split(' ');
    _.each(deletedMessageIds, (id) => {
      this.deleteMessageByMessageId(id);
      this._chatMessageService.removeChatMessageUnderChatByMessageId(id);
    });
    this._chatMessageService.updateChatMessageSubject();
    this._chatMessageService.updateActiveChatRoomMessageSubject();
  }

  deleteMessageByMessageId(messageId: string): void {
    delete this.messages[messageId];
  }

  /**
   * Get message by message_id
   *
   * @param {string} messageId
   * @returns
   * @memberof MessagesService
   */
  getMessageByMessageId(messageId: string) {
    return this.messages[messageId];
  }

  /**
   * Get all messages under target chat id (currently not using)
   *
   * @param {string} targetChatId
   * @returns
   * @memberof MessagesService
   */
  getMessageUnderChatId(targetChatId: string) {
    var targetMessages = [];
    _.each(this.messages, function (m) {
      _.each(m.chat_ids, function (chatId) {
        if (chatId === targetChatId) {
          targetMessages.push(m);
        }
      });
    });
    return _.sortBy(targetMessages, ['timestamp']);
  }

  /**
   * Get all child messages by parent message id
   * (Main usage: News Comment)
   *
   * @param {string} messageId
   * @returns {Message[]}
   * @memberof MessagesService
   */
  getAllChildsByParentMessageId(messageId: string): Message[] {
    return _.filter(this.messages, {'comment_parent_id': messageId});
  }

  /**
   * Get all comments under news
   * 1. Do not add messages that are deleted/expired
   * 2. Build first-level & second-level structure
   *
   * @param {string} messageId
   * @returns {Message}
   * @memberof MessagesService
   */
  getFullNewsWithCommentsByMessageId(messageId: string): Message {
    let news = this.getMessageByMessageId(messageId);
    if (news) {
      news = JSON.parse(JSON.stringify(news));
      if (news.comment_count !== 0) {
        news.comments = _.orderBy(news.comments, 'timestamp');
        for (var i in news.comments) {
          let firstLevelComment = news.comments[i];
          let firstLevelCommentMessage = this.getMessageByMessageId(firstLevelComment.message_id);
          if (firstLevelCommentMessage) {
            let child = this.getAllChildsByParentMessageId(firstLevelCommentMessage.message_id);
            child = _.filter(child, (c) => {
              return !this._utilitiesService.checkIfMessageExpired(c.expiry_time);
            });
            firstLevelComment.comments = _.orderBy(child, 'timestamp');
          }
        }
        news.comments = _.filter(news.comments, (c) => {
          return !this._utilitiesService.checkIfMessageExpired(c.expiry_time);
        });
      } else {
        news.comments = [];
      }
      return news;
    }
  }

  // Active News Modal tracking - comments

  /**
   * Init active message subject by message id
   * (Main usage: News comment tracking in news modal)
   *
   * @param {string} messageId
   * @memberof MessagesService
   */
  initActiveMessageSubject(messageId: string): void {
    this.activeMessageId = messageId;
    this.activeMessage$ = new BehaviorSubject<Message>(<Message>{});
  }

  /**
   * Delete active message subject
   *
   * @memberof MessagesService
   */
  deleteActiveMessageSubject(): void {
    this.activeMessageId = '';
    this.activeMessage$ = null;
  }

  /**
   * Update active message subject if active message exists
   *
   * @memberof MessagesService
   */
  updateActiveMessageSubject(): void {
    if (this.activeMessageId) {
      this.activeMessage$.next(this.getActiveMessage());
    }
  }

  /**
   * Get active message (full news with comment)
   *
   * @returns {Message}
   * @memberof MessagesService
   */
  getActiveMessage(): Message {
    return this.getFullNewsWithCommentsByMessageId(this.activeMessageId);
  }

  onEmojiClick(message: Message, isSent: boolean | string, emojiStr: string): void {
    // Do not send emoji if the WebSocket is not connected
    if (!this._socketService._isConnected) {
      this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.WEBSOCKET.OFFLINE');
      this._loggerService.debug('Web socket is disconnected..., stop to send emoji');
      return;
    }

    if (isSent) {
      let isSentEmojiDup = this._infoMessageService.checkIfSentEmojiIsDuplicate(message, emojiStr)
      
      if (isSentEmojiDup) {
        // Sent the same emoji as last time
        this.unSendEmoji(message.message_id, emojiStr);
      } else {
        // Sent a new emoji, then swap with the previous emoji
        this.swapEmoji(message.message_id, emojiStr)
      }
    } else {
      this.sendEmoji(message.message_id, emojiStr);
    }
  }

  // ----- AMQP -----

  /**
   * Send Message Delivery via socket, separate message ids in chunk
   *
   * @param {string} chatId
   * @param {string[]} messageIds
   * @returns
   * @memberof MessagesService
   */
  sendMessageDelivery(chatId: string, messageIds: string[]) {
    if (!messageIds) {
      this._loggerService.warn('Trying to send message delivery without message ids, rejected');
      return;
    }
    let chunks = this._utilitiesService.getChunkedMessageArr(messageIds);
    _.each(chunks, (c) => {
      this.sendMessageDeliveryRequest(chatId, c);
    });
  }

  /**
   * Real send message delivery request
   *
   * @param {string} chatId
   * @param {string[]} messageIds
   * @memberof MessagesService
   */
  sendMessageDeliveryRequest(chatId: string, messageIds: string[]) {
    let correlationId = 'MESSAGE_DELIVERY_' + chatId + '_' + _.now();
    let routingKey = '/' + chatId;
    let headers = {
      'correlation-id': correlationId,
      'device_token': this._localStorageManagerService.getDeviceToken(),
      'type': MessageTypeConstant.MESSAGE_DELIVERY,
      'priority': 0
    };
    let body = messageIds.join(' ');

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }

  /**
   * Send Message Read via socket, separate message ids in chunk
   *
   * @param {string} chatId
   * @param {string[]} messageIds
   * @returns
   * @memberof MessagesService
   */
  sendMessageRead(chatId: string, messageIds: string[]) {
    if (!messageIds) {
      this._loggerService.warn('Trying to send message bulk read without message ids, rejected');
      return;
    }
    let chunks = this._utilitiesService.getChunkedMessageArr(messageIds);
    _.each(chunks, (c) => {
      this.sendMessageReadRequest(chatId, c);
    });
  }

  sendMessageAck(chatId: string, messageIds: string[], callback?) {
    if (!messageIds) {
      this._loggerService.warn('Trying to send message bulk read without message ids, rejected');
      return;
    }
    let chunks = this._utilitiesService.getChunkedMessageArr(messageIds);
    _.each(chunks, (c) => {
      this.sendMessageAckRequest(chatId, c, callback);
    });
  }

  /**
   * Real send message read request
   *
   * @param {string} chatId
   * @param {string[]} messageIds
   * @memberof MessagesService
   */
  sendMessageReadRequest(chatId: string, messageIds: string[]) {
    let correlationId = 'BULK_READ_' + chatId + '_' + _.now();
    let routingKey = '/' + chatId;
    let headers = {
      'correlation-id': correlationId,
      'device_token': this._localStorageManagerService.getDeviceToken(),
      'type': MessageTypeConstant.BULK_READ,
      'priority': 0
    };
    let body = messageIds.join(' ');

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }

  sendMessageAckRequest(chatId: string, messageIds: string[], callback?) {
    let correlationId = 'ACK_MESSAGE_' + chatId + '_' + _.now();
    let routingKey = '/' + chatId;
    let headers = {
      'correlation-id': correlationId,
      'device_token': this._localStorageManagerService.getDeviceToken(),
      'type': MessageTypeConstant.MESSAGE_ACKNOWLEDGEMENT,
      'priority': 1
    };
    let body = messageIds.join(' ');

    this._socketService.sendMessage(routingKey, headers, body, correlationId, callback);
  }

  sendMessageDelete(chatId: string, messageIds: string[]) {
    let correlationId = 'MESSAGE_DELETE_' + chatId + '_' + _.now();
    let routingKey = '/' + chatId;
    let headers = {
      'correlation-id': correlationId,
      'device_token': this._localStorageManagerService.getDeviceToken(),
      'type': MessageTypeConstant.MESSAGE_DELETE
    };
    let body = messageIds.join(' ');

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }

  sendMessageStar(chatId: string, messageIds: string[]) {
    let correlationId = 'MESSAGE_STAR_' + chatId + '_' + _.now();
    let routingKey = '/' + chatId;
    let headers = {
      'correlation-id': correlationId,
      'device_token': this._localStorageManagerService.getDeviceToken(),
      'type': MessageTypeConstant.MESSAGE_STAR,
      'priority': 0
    };
    let body = messageIds.join(' ');

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }

  sendMessageUnStar(chatId: string, messageIds: string[]) {
    let correlationId = 'MESSAGE_UNSTAR_' + chatId + '_' + _.now();
    let routingKey = '/' + chatId;
    let headers = {
      'correlation-id': correlationId,
      'device_token': this._localStorageManagerService.getDeviceToken(),
      'type': MessageTypeConstant.MESSAGE_UNSTAR,
      'priority': 0
    };
    let body = messageIds.join(' ');

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }

  /**
   * Send Emoji to message
   *
   * @param {string} messageId
   * @param {string} emoji
   * @returns
   * @memberof MessagesService
   */
  sendEmoji(messageId: string, emoji: string) {
    if (!messageId || !emoji) {
      this._loggerService.warn('Trying to send message emoji without message id / emoji, rejected');
      return;
    }
    let correlationId = AMQPRoutingKey.CORRELATION_ID.SEND_EMOJI;
    let routingKey = AMQPRoutingKey[correlationId].replace('{message_id}', messageId);
    correlationId += '_' + messageId + '_' + _.now();
    let headers = {
      'device_token': this._localStorageManagerService.getDeviceToken(),
      'correlation-id': correlationId
    };
    let body = emoji;

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }

  /**
   * delete all existing emojis and insert the emoji specified for the target message
   *
   * @param {string} messageId
   * @param {string} emoji
   * @returns
   * @memberof MessagesService
   */
  swapEmoji(messageId: string, emoji: string) {
    if (!messageId || !emoji) {
      this._loggerService.warn('Trying to swap message emoji without message id / emoji, rejected');
      return;
    }
    let correlationId = AMQPRoutingKey.CORRELATION_ID.SWAP_EMOJI;
    let routingKey = AMQPRoutingKey[correlationId].replace('{message_id}', messageId);
    correlationId += '_' + messageId + '_' + _.now();
    let headers = {
      'device_token': this._localStorageManagerService.getDeviceToken(),
      'correlation-id': correlationId
    };
    let body = emoji;

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }

  /**
   * Un-Send Emoji to message
   *
   * @param {string} messageId
   * @param {string} emoji
   * @returns
   * @memberof MessagesService
   */
  unSendEmoji(messageId: string, emoji: string) {
    if (!messageId || !emoji) {
      this._loggerService.warn('Trying to unsend message emoji without message id / emoji, rejected');
      return;
    }
    let correlationId = AMQPRoutingKey.CORRELATION_ID.UNSEND_EMOJI;
    let routingKey = AMQPRoutingKey[correlationId].replace('{message_id}', messageId);
    correlationId += '_' + messageId + '_' + _.now();
    let headers = {
      'device_token': this._localStorageManagerService.getDeviceToken(),
      'correlation-id': correlationId
    };
    let body = emoji;

    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }

  /**
   * Send comment under parent message
   *
   * @param {string} messageId
   * @param {number} msgType
   * @param {Object} msgBody
   * @returns
   * @memberof MessagesService
   */
  sendMessageComment(messageId: string, msgType: number, msgBody: Object) {
    if (!messageId || !msgType || !msgBody) {
      this._loggerService.warn('Trying to send message comment without message id / message type / message body, rejected');
      return;
    }
    let correlationId = AMQPRoutingKey.CORRELATION_ID.SEND_COMMENT;
    let routingKey = AMQPRoutingKey[correlationId].replace('{message_id}', messageId);
    correlationId += '_' + messageId + '_' + _.now();
    let headers = {
      'correlation-id': correlationId,
      'device_token': this._localStorageManagerService.getDeviceToken(),
      'type': msgType
    };
    let body = JSON.stringify(msgBody);
    this._socketService.sendMessage(routingKey, headers, body, correlationId);
  }

  filterAndSortSearchedMessages(msgs: Message[], keyword?: string): Message[] {
    // Sort by timestamp
    msgs = _.orderBy(msgs, ['timestamp'], ['desc']);

    // Filter only real chat room's messages
    msgs = _.filter(msgs, (m) => {
      return this._chatService.checkIfChatIsChatRoomByChatId(m.chat_ids[0]);
    });
    // Filter out image/video/audio messages
    msgs = _.filter(msgs, (m: Message) => {
      if (m.type == MessageTypeConstant.ATTACHMENT) {
        m.parsedBody = JSON.parse(m.body);
        if (m.parsedBody && m.parsedBody.message) {
          if (m.parsedBody.message.indexOf(keyword) != -1) {
            return true;
          }
        }
        let attachment = m.attachments[0];
        if (attachment) {
          let type = this._fileManagerService.getAttachmentType(attachment.attachment_id);
          switch (type) {
            case AttachmentTypeConstant.IMAGE:
            case AttachmentTypeConstant.AUDIO:
            case AttachmentTypeConstant.VIDEO:
              return false;
          }
        }
      }
      return true;
    });

    return msgs;
  }
}
