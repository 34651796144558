<div class="tn-chat-messages-wrapper" #messagesWrapper>

  <div class="loading-history-indicator" *ngIf="isLoadingHistory">
    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
  </div>

  <div #messageRow class="tn-chat-message" [id]="m.message_id" *ngFor="let m of parsedMessages">

    <div class="message-row-wrapper" 
      *ngIf="(
        m.type == MSG_TYPE.TEXT || 
        m.type == MSG_TYPE.ATTACHMENT || 
        m.type == MSG_TYPE.LOCATION ||
        m.type == MSG_TYPE.STICKER
      ) && 
      (!m.isRecalled); 
      else infoRow">

      <div class="message-row" 
        [ngClass]="{
          'message-not-editing': chatRoomMode == CHAT_ROOM_MODE.EDIT && editingMessageId && m.message_id !== editingMessageId,
          'message-by-me': m.isSentByMe,
          'sticker': m.type == MSG_TYPE.STICKER && !m.comment_parent,
          'emoji-gap': m.emojiDisplay && m.emojiDisplay.length !== 0,
          'message-ack-gap':
            isEnableMsgAck &&
            !m.isSentByMe &&
            m.parsedBody.acknowledgement &&
            m.parsedBody.acknowledgement.mode !== 'disabled'
        }">
        <div class="sender-avatar-wrapper" 
          [ngClass]="{'clickable': chatRoomMode == CHAT_ROOM_MODE.NORMAL}">
          <div class="avatar default-contact-avatar" 
            *ngIf="!m.isSentByMe" 
            (click)="onUserClick(m.senderContact)"
            [ngStyle]="{'background-image': m.senderContact.avatarImageSrc}">
          </div> 
        </div>
        
        <div class="message-body">
          <div 
            class="sender-name text-color-secondary"
            [ngClass]="{
              'important-user': isEnableImportantUsers,
              'global-vip': m.senderContact.importantLevel === IMPORTANT_LEVEL.GLOBAL,
              'individual-vip': m.senderContact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL
            }"
            *ngIf="!m.isSentByMe && !m.isWhisper"
           >
            {{m.senderContact.name}}
            <ng-container *ngIf="showUserField && m.senderContact[userField]" >({{m.senderContact[userField]}})</ng-container>
            <ng-container *ngIf="isEnableImportantUsers">
              <div class="global-vip-icon" *ngIf="m.senderContact.importantLevel === IMPORTANT_LEVEL.GLOBAL"></div>
              <div class="individual-vip-icon" *ngIf="m.senderContact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL"></div>
            </ng-container>
            <ng-container *ngIf="m.senderContact.deleted">
              {{'WEBCLIENT.CONTACT.DELETED_POSTFIX' | translate}}
            </ng-container>
          </div>
          <div 
            class="sender-name"
            [ngClass]="{
              'important-user': isEnableImportantUsers,
              'my-msg': m.isSentByMe,
              'global-vip':
                m.senderContact.importantLevel === IMPORTANT_LEVEL.GLOBAL ||
                m.whisperContact.importantLevel === IMPORTANT_LEVEL.GLOBAL,
              'individual-vip':
                m.senderContact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL ||
                m.whisperContact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL
            }"
            *ngIf="m.isWhisper"
          >
            <ng-container *ngIf="m.isSentByMe">
              {{'WEBCLIENT.CHATROOM.NAME_WHISPER_LABEL' | translate: {'USER': m.whisperContact.name} }}  
              <ng-container *ngIf="isEnableImportantUsers">
                <div class="global-vip-icon" *ngIf="m.whisperContact.importantLevel === IMPORTANT_LEVEL.GLOBAL"></div>
                <div class="individual-vip-icon" *ngIf="m.whisperContact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL"></div>
              </ng-container>          
            </ng-container>
            <ng-container *ngIf="!m.isSentByMe">
              {{'WEBCLIENT.CHATROOM.NAME_WHISPER_LABEL' | translate: {'USER': m.senderContact.name} }}   
              <ng-container *ngIf="isEnableImportantUsers">
                <div class="global-vip-icon" *ngIf="m.senderContact.importantLevel === IMPORTANT_LEVEL.GLOBAL"></div>
                <div class="individual-vip-icon" *ngIf="m.senderContact.importantLevel === IMPORTANT_LEVEL.INDIVIDUAL"></div>
              </ng-container>         
            </ng-container>
          </div>

          <div class="message-id" *ngIf="isShowMessageID">
            {{getMessageIdDisplay(m)}}
          </div>

          <div class="message-content-row" 
            [ngClass]="{
              'message-by-me': m.isSentByMe, 
              'message-whisper': m.isWhisper,
              'message-sending': !m.message_id,
              'message-important': m.parsedBody.important_level == 1
            }">

            <div class="message-bubble-tip">
            </div>

            <div class="message-content" 
              [ngClass]="{
                'text': m.type == MSG_TYPE.TEXT && !m.comment_parent,
                'horizontal-hover': m.type == MSG_TYPE.TEXT || m.type == MSG_TYPE.LOCATION, 
                'angled-hover': m.type == MSG_TYPE.ATTACHMENT || m.comment_parent
              }"
              (click)="onClick($event, m, true)">
              <ng-container *ngIf="isEnableMsgAck && m.parsedBody.acknowledgement">
                <div class="critical-message">
                  <div class="critical-msg-icon"></div>
                </div>
              </ng-container>

              <ng-container *ngIf="!isUnlockedEncryptedMessage && m.parsedBody.is_encrypted">
                <!-- encrypted message preview -->
                <div class="encrypted-message" 
                  [ngClass]="{'clickable': chatRoomMode == CHAT_ROOM_MODE.NORMAL}">
                  <div class="encrypted-message-lock-icon"></div>
                  <div class="encrypted-message-preview">
                    {{'WEBCLIENT.CHATROOM.ENCRYPTED_MESSAGE.MESSAGE_PREVIEW' | translate}}
                  </div>
                </div>
              </ng-container>
              
              <ng-container *ngIf="isUnlockedEncryptedMessage || !m.parsedBody.is_encrypted">
                <div class="displaying-content">
                  <!-- replying message body -->
                  <tn-message-parent
                    class="clickable"
                    [message]="m.comment_parent"
                    [isSentByMe]="m.isSentByMe"
                    (click)="onParentMessage(m.comment_parent, m)" 
                    *ngIf="m.comment_parent">
                  </tn-message-parent>

                  <!--opengraph of hyperlink-->
                  <!--tn-message-ogp
                    [body]="m.parsedBody">
                  </tn-message-ogp-->

                  <!-- message body -->
                  <tn-text-message 
                    [body]="m.parsedBody" 
                    (onUserClick)="onUserClick($event)"
                    (onHashtagClick)="onHashtagClick($event)"
                    *ngIf="m.type == MSG_TYPE.TEXT">
                  </tn-text-message>

                  <tn-attachment-message
                    [isSentByMe]="m.isSentByMe"
                    [body]="m.parsedBody" 
                    [attachments]="m.attachments" 
                    [messageId]="m.message_id"
                    [isClickable]="chatRoomMode == CHAT_ROOM_MODE.NORMAL"
                    *ngIf="m.type == MSG_TYPE.ATTACHMENT">
                  </tn-attachment-message>

                  <tn-location-message 
                    [body]="m.parsedBody"
                    [ngClass]="{'clickable': chatRoomMode == CHAT_ROOM_MODE.NORMAL}"
                    *ngIf="m.type == MSG_TYPE.LOCATION">
                  </tn-location-message>

                  <tn-sticker-message 
                    [body]="m.parsedBody"
                    *ngIf="m.type == MSG_TYPE.STICKER">
                  </tn-sticker-message>
                </div>

                <div class="hovering-menu">
                  <tn-hover-dropdown-menu
                    *ngIf="m.message_id"
                    [id]="m.message_id"
                    (click)="onClick($event, m, false)">
                  </tn-hover-dropdown-menu>
                </div>
              </ng-container>

              <ng-container>
                <div
                  class="emoji-list"
                  [ngClass]="{ 'emoji-other': !m.isSentByMe, 'emoji-me': m.isSentByMe }"
                  *ngIf="m.emojiDisplay && m.emojiDisplay.length"
                >
                  <ng-container *ngFor="let emoji of m.emojiDisplay; let i = index">
                    <div
                      *ngIf="i <= 2"
                      class="emoji-wrapper clickable"
                      [ngClass]="{
                        'select-color': m.isSentEmoji === emoji.content || i === 2,
                        'no-hover no-clickable': !isEnableMsgEmoji && !m.isSentByMe && i !== 2
                      }"
                      (click)="onEmojiDetailClick($event, m, emoji.content, i)"
                    >
                      <ng-container *ngIf="i < 2">
                        {{ emoji.content }}
                        <span class="emoji-count" *ngIf="emoji.count > 1">{{ emoji.count }}</span>
                      </ng-container>
                      <ng-container *ngIf="i === 2">
                        <span class="emoji-count-preview">+{{ getEmojiPreviewCount(m) }}</span>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              
              <div *ngIf="isEnableMsgAck && m.parsedBody.acknowledgement">
                <div class="msg-ack-row"
                  [ngClass]="{
                    'border-color-primary theme-color-primary msg-acked': m.isAcked,
                    'msg-ack clickable': !m.isAcked
                  }" 
                  (click)="sendMessageAck(m)"
                  *ngIf="!m.isSentByMe && m.parsedBody.acknowledgement.mode !== 'disabled'">
                    <div *ngIf="m.isAcked" class="acked-button">
                      {{'WEBCLIENT.CHATROOM.ACK.ACKED' | translate}}
                      <div class="acknowledged-icon"></div>
                    </div>
                    <div *ngIf="!m.isAcked" class="ack-button">
                      {{'WEBCLIENT.CHATROOM.ACK.ACK' | translate}}
                    </div>
                </div>
              </div>
            </div>

            <!-- Message Edited Marker -->
            <div class="message-edit-container" *ngIf="m.isSentByMe && (m.isUpdated || m.parsedBody.is_updated)">
              <span>
                {{ 'WEBCLIENT.CHATROOM.EDITED' | translate }}
              </span>
            </div>

            <div
              class="message-info"
              [ngClass]="{ 'read-ticks': isEnableReadTicks }"
              *ngIf="m.message_id"
            >
              <!-- default 'Read' mark -->
              <ng-container *ngIf="m.isSentByMe && !isEnableReadTicks">
                <div class="read-by-count" *ngIf="m.readByOtherCount > 0">
                  <span *ngIf="!chat.isGroup">{{ 'WEBCLIENT.CHATROOM.READ' | translate }}</span>
                  <span *ngIf="chat.isGroup">
                    {{ 'WEBCLIENT.CHATROOM.READ_GROUP' | translate : { NUM: m.readByOtherCount } }}
                  </span>
                </div>
              </ng-container>
              
              <div class="message-time" [ngClass]="{ 'reverse-order': !m.isSentByMe }">
                <div class="message-starred" *ngIf="m.isStarredByMe && isEnableStarMessage">
                  <i class="text-color-primary fa fa-star"></i>
                </div>

                <span>
                  {{ m.timestamp | tnDate : 'CHAT_MSG_TIME' }}
                </span>
              </div>

              <!-- two ticks -->
              <ng-container *ngIf="m.isSentByMe && isEnableReadTicks">
                <ng-container *ngIf="!chat.isGroup">
                  <div *ngIf="m.readByOtherCount === 0">
                    <div class="message-single-tick" *ngIf="m.deliverByOtherCount === 0"></div>
                    <div class="message-deliver-tick" *ngIf="m.deliverByOtherCount > 0"></div>
                  </div>

                  <div *ngIf="m.readByOtherCount > 0">
                    <div class="message-read-tick"></div>
                  </div>
                </ng-container>
                <ng-container *ngIf="chat.isGroup">
                  <div *ngIf="m.readByOtherCount !== totalChatMembers">
                    <div class="message-single-tick" *ngIf="m.deliverByOtherCount < totalChatMembers"></div>
                    <div class="message-deliver-tick" *ngIf="m.deliverByOtherCount === totalChatMembers"></div>
                  </div>

                  <div class="message-read-tick" *ngIf="m.readByOtherCount === totalChatMembers"></div>
                </ng-container>
              </ng-container>
            </div>

            <!-- message send loading -->
            <div class="message-info" *ngIf="!m.message_id">
              <div class="send-loading" *ngIf="currI18nKey === 'en'">
                <div class="l-1 letter">S</div>
                <div class="l-2 letter">e</div>
                <div class="l-3 letter">n</div>
                <div class="l-4 letter">d</div>
                <div class="l-5 letter">i</div>
                <div class="l-6 letter">n</div>
                <div class="l-7 letter">g</div>
                <div class="l-8 letter">.</div>
                <div class="l-9 letter">.</div>
                <div class="l-10 letter">.</div>
              </div>

              <div class="zh-send-loading" *ngIf="currI18nKey === 'zh-Hant'">
                <div class="zhl-1 zh-letter">發</div>
                <div class="zhl-2 zh-letter">送</div>
                <div class="zhl-3 zh-letter">中</div>
                <div class="zhl-4 zh-letter">.</div>
                <div class="zhl-5 zh-letter">.</div>
                <div class="zhl-6 zh-letter">.</div>
              </div>

              <div class="zh-send-loading" *ngIf="currI18nKey === 'zh-Hans'">
                <div class="zhl-1 zh-letter">发</div>
                <div class="zhl-2 zh-letter">送</div>
                <div class="zhl-3 zh-letter">中</div>
                <div class="zhl-4 zh-letter">.</div>
                <div class="zhl-5 zh-letter">.</div>
                <div class="zhl-6 zh-letter">.</div>
              </div>
            </div>
          </div>

          <div class="message-content-row" *ngIf="isAckToRead">
            <div class="message-content ack-row"
              [ngClass]="{
                'acked': m.isReadByMe,
                'ack clickable': !m.isReadByMe
              }" 
              (click)="ackMessage(m)"
              *ngIf="!m.isSentByMe">
              <div class="ack-button">
                <span *ngIf="m.isReadByMe">
                  <i class="fa fa-fw fa-check"></i>
                  {{'WEBCLIENT.CHATROOM.ACK.ACKED' | translate}}
                </span>
                <span *ngIf="!m.isReadByMe">
                  <i class="fa fa-fw fa-bell"></i>
                  {{'WEBCLIENT.CHATROOM.ACK.ACK' | translate}}
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="forward-selector" 
        *ngIf="chatRoomMode != CHAT_ROOM_MODE.NORMAL && chatRoomMode != CHAT_ROOM_MODE.EDIT">
        <i class="fa fa-fw fa-2x clickable"
          (click)="onSelect(m)" 
          *ngIf="isDisableEncrypyedMsgDownload && !m.parsedBody.is_encrypted"
          [ngClass]="{
            'empty fa-square-o': !m.isSelected, 
            'chatroom-main-color fa-check-square-o': m.isSelected
          }">
        </i>
        <i class="fa fa-fw fa-2x clickable"
          (click)="onSelect(m)" 
          *ngIf="!isDisableEncrypyedMsgDownload && (isUnlockedEncryptedMessage || !m.parsedBody.is_encrypted)"
          [ngClass]="{
            'empty fa-square-o': !m.isSelected, 
            'chatroom-main-color fa-check-square-o': m.isSelected
          }">
        </i>
      </div>

    </div>
    
    <ng-template #infoRow>
      <tn-info-message [message]="m"></tn-info-message>
    </ng-template>


  </div>

</div>
