/**
 * EMSD Configs
 * 
 * Created Date - 2024-08-23
 */

import { TeamNoteConfig } from "../../configs/models/config";

export const EmsdConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: "emsd"
  },
  GENERAL: {
    IS_ENABLE_TNC: true,
    PAGE_TITLE: "EMSD Messenger",
    IS_SHOW_DOAMIN_INPUT: false,
    DOWNLOAD_FILE_WITH_PREFIX: false,
    ATTACHMENT_SIZE_LIMIT: 25,
    NOTIFICATION_DISPLAY_DURATION: 8
  },
  LANGUAGE: {
    DEFAULT: "zh-cht",
    EXCLUSION: ['zh-chs']
  },
  IMAGES: {
    NOTIFICATION_ICON: "assets/images/clients/emsd/login_logo.png",
    FAVICON: "assets/images/clients/emsd/login_logo.png",
    // FAVICON_ALERT: "assets/images/clients/dse/favicon_alert.png"
  },
  MAP: {
    // API_KEY: "AIzaSyA1aE0_j-nvvjtjqoB8-0wcMTWJg-iKLjM"
  },
  WEBCLIENT: {
    GENERAL: {
      // IS_ALLOW_AUTO_EXTEND_SESSION: true
      TERMS_AND_CONDITION_FILE_DEFAULT: 'assets/tandc/emsd/tandc.html',
      TERMS_AND_CONDITION_FILE_PATH: {
        'zh-cht': 'assets/tandc/emsd/tandc_tc.html',
        'zh-chs': 'assets/tandc/emsd/tandc_sc.html'
      },
      IS_ENABLE_CHAT_REMOVE: false
    },
    CHATROOM: {
      ENABLE_ENCRYPTED_MESSAGE: true,
      // ENABLE_EXPORT_CHAT: true,
      ENABLE_WHISPER: false,
      // IS_ENABLE_SEARCH: true,
      // IS_ENABLE_HASHTAG: true,
      // IS_ENABLE_MENTION: true
      IS_ALLOW_ALL_FILE_TYPE: true,
      IS_DISABLE_LOCATION_UPLOAD: true
    },
    CONTACT: {
      IS_ENABLE_INACTIVE_USER_CHECK: false,
      FORCE_CONTACT_ACTIONS: true,

      LIST_DESC_FIELDS: [
        'title',
        'department',
        'public_message'
      ],
      CARD_DESC_FIELDS: [
        'title',
        'department',
        'email',
        'mobile',
        'public_message'
      ],
    },
    WATERMARK: {
      // IS_NEED_DATE_TIME: false,
    },
    CORPORATE_MATERIAL: {
      IS_CHECK_MATERIAL_DOWNLOAD_PERMISSION: true
    },
  },

  UI: {
    USE_NEW_LOGIN_PAGE_UI: true
  }
};
